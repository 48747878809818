// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buy-proxies-js": () => import("./../../../src/pages/buy-proxies.js" /* webpackChunkName: "component---src-pages-buy-proxies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invoices-js": () => import("./../../../src/pages/invoices.js" /* webpackChunkName: "component---src-pages-invoices-js" */),
  "component---src-pages-ip-auth-js": () => import("./../../../src/pages/ip-auth.js" /* webpackChunkName: "component---src-pages-ip-auth-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-residential-rotating-index-js": () => import("./../../../src/pages/residential/rotating/index.js" /* webpackChunkName: "component---src-pages-residential-rotating-index-js" */)
}

