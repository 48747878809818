import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBe1f7SokxS0BqdKTb1-9M-ejLz4kXogEQ",
  authDomain: "serpkit.firebaseapp.com",
  projectId: "serpkit",
  storageBucket: "serpkit.appspot.com",
  messagingSenderId: "669285014595",
  appId: "1:669285014595:web:708c37a1359df0f1c848f6",
};

if (typeof window !== "undefined") {
  firebase.initializeApp(firebaseConfig);
}

export default firebase;
