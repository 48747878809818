import React, { useEffect } from "react";
import axios from "axios";
import firebase from "../../config/firebase";
import Cookies from "js-cookie";

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const initialState = {
  userToken: null,
  user: null,
  refreshUser: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_USER_TOKEN": {
      return {
        ...state,
        userToken: action.userToken,
      };
    }
    case "SET_USER": {
      return {
        ...state,
        user: action.user,
      };
    }
    case "REFRESH_USER": {
      return {
        ...state,
        refreshUser: action.refreshUser,
      };
    }
    default:
      throw new Error("Bad Action Type");
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  /**
   * Fetch user on firebase authenticated
   */
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (userAuth) => {
      let idToken = await userAuth?.getIdTokenResult();

      // if (idToken?.expirationTime) {
      //   //if (!Cookies.get("login_cookie")) {
      //   // Cookies.set("login_cookie", true, {
      //   //   expires: new Date(idToken.expirationTime),
      //   // });
      //   //}
      // }

      dispatch({
        type: "SET_USER_TOKEN",
        userToken: idToken?.token ? idToken.token : null,
      });

      if (idToken && idToken.token) {
        fetchUser(idToken.token);
      }
    });
  }, []);

  /**
   * Refresh user if needed
   */
  useEffect(() => {
    if (state.refreshUser) {
      fetchUser(state.userToken);
    }
  }, [state.refreshUser]);

  /**
   * Fetch user from backend
   *
   * @param {*} token
   */
  const fetchUser = (token) => {
    axios
      .get(`${process.env.GATSBY_API_BASE}/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Set user
        dispatch({
          type: "SET_USER",
          user: response.data,
        });
      })
      .catch(async (error) => {
        if (403 === error.response.status) {
          await firebase.auth().signOut();

          Cookies.remove("login_cookie");

          dispatch({
            type: "SET_USER_TOKEN",
            userToken: null,
          });

          dispatch({
            type: "SET_USER",
            user: null,
          });

          window.location = "/login";
        }
      })
      .finally(() => {
        dispatch({
          type: "REFRESH_USER",
          refreshUser: false,
        });
      });
  };

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
