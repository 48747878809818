//import "bootstrap/dist/css/bootstrap.min.css";

import "./src/style/global.css";
import "./src/scss/main.scss";

import React from "react";
import GlobalContextProvider from "./src/context/GlobalContextProvider";

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>;
};
